import Big from 'big.js'

const xapp = window.xapp
let xappJWTxumm

const status = () => {
    return new Promise((resolve, reject) => {
        function message(event) {
            console.log('payload response xaman:', event)
            // window.removeEventListener("message", message)

            if(event.reason === 'SIGNED') return resolve()
            else return reject('Order Cancelled')
        }
        xapp.on('payload', message)
    })
}

const payloadResult = async (uuid) => {
    if(!uuid) throw 'Can\'t fetch Transaction result'
    try {
        const res = await fetch(`https://xumm.app/api/v1/xapp-jwt/payload/${uuid}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${xappJWTxumm}`
            }
        })

        if(res.status < 200 || res.status > 299) {
            throw `Status: ${res.status}`
        }

        const data = await res.json()
        return data

    } catch(e) {
        throw 'Error fetching Transaction result'
    }
}

const initPayload = async (payload) => {
    try {
        const res = await fetch('https://xumm.app/api/v1/xapp-jwt/payload', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${xappJWTxumm}`
            },
            body: JSON.stringify(payload)
        })

        if(res.status < 200 || res.status > 299) {
            throw `Status: ${res.status}`
        }

        const data = await res.json()
        return data.uuid
    } catch(e) {
        alert(`Error with posting Transaction ${e}`)
    }
}

const submitPayment = async (data, jwt) => {
    if(!jwt) throw new Error('No JWT given')
    else xappJWTxumm = jwt
    console.log(data)

    const xrpToDrops = (xrp) => {
        try {
            return Big(xrp).times(1000000).toFixed(0, 3).toString()
        } catch(e) {
            console.error(e)
            throw 'Amount is not a valid number'
        }
    }

    try {
        if(isNaN(data.amount) || isNaN(data.destinationTag)) throw 'Amount is not a valid number'
        if(data.amount <= 0 || data.destinationTag <= 0) throw 'Amount is not a valid number'
        if((data.userWalletAddress !== data.walletOrigin) && data.userWalletAddress) throw 'Address received is different than from Xaman'
        if(data.currency !== 'XRP') throw 'Crypto Currency is not XRP'

        const payload = {
            TransactionType: 'Payment',
            Account: data.walletOrigin || data.walletAddress,
            Amount: xrpToDrops(data.amount),
            Destination: data.walletDestination,
            DestinationTag: data.destinationTag
        }
        console.log(payload)
        const xummPaymentObj = { txjson: payload, options: { expire: 2 } }
        if(data.orderId) xummPaymentObj['custom_meta'] = { blob: { external_id: data.orderId }, instruction: `orderId: ${data.orderId}` }

        const uuid = await initPayload(xummPaymentObj)
        xapp.openSignRequest({ uuid })
        await status()
        const result = await payloadResult(uuid)

        if(result.response.dispatched_nodetype === 'MAINNET' && result.response.dispatched_result === 'tesSUCCESS') {
            return true
        } else {
            throw 'Check payment details and contact provider for Support'
        }

    } catch(e) {
        alert(e)
    }
}

export default submitPayment