<template>
  <LogoSVG style="height: 20px; margin: 20px auto;" />
  <SupportIcon class="support-button" @click="supportLinkClick('https://guardarian.freshdesk.com/support/home')"/>
  <LoaderSpinner v-if="loading" style="margin: auto;"/>
  <OrderForm v-else-if="tcAccept == true" :walletAddress="walletAddress" :fiatType="fiatType || 'EUR'" />

  <div class="terms-condition-container" v-else>
    <div class="tc-info">
        <p>By agreeing to the terms of this User Agreement, you declare that you have read, understood and agree to the content and agree to our Privacy Statement and disclaimer.</p>
        <p>Guardarian is a third-party on & offramp service provider. You will be redirected to  Guardarian's widget and website. XRPL Labs is not resposible for the content or services provided by Guardarian or any damages or loss it may cause to you due to the use of Guardarian's services. Please contact Guardarian directly. Please read the terms of service of Guardarian.</p>
    </div>
    <button class="btn blue" @click="acceptTermsAndConditions()">Accept</button>
  </div>
</template>

<script>
import OrderForm from './components/OrderForm.vue';
import LoaderSpinner from './components/LoaderSpinner.vue';
import SupportIcon from './assets/SupportIcon.vue'
import LogoSVG from './assets/logo-svg.vue'

import xummPayment from './plugins/payment'

export default {
  name: 'App',
  components: {
    OrderForm,
    LoaderSpinner,
    SupportIcon,
    LogoSVG
  },
  data() {
    return {
        walletAddress: null,
        fiatType: null,
        tcAccept: false,
        loading: true,
        userData: null
    }
  },
  methods: {
    alert(msg) {
      alert(msg)
    },
    async supportLinkClick(url) {
      try{
        await window.xapp.openBrowser({ url: url })
      } catch(e) {
        console.log(e)
      }
    },
    async acceptTermsAndConditions() {
        this.loading = true
        await this.setUserData('onboarding', { init: true })
        this.tcAccept = true
        this.loading = false
    },
    async getTokenData (ott) {
        try {
            const res = await fetch('https://xumm.app/api/v1/xapp-jwt/authorize', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'x-api-key': process.env.VUE_APP_XAPP_KEY,
                    'x-api-ott': ott
                }
            })
            const data = await res.json()
            this.userData = data

            this.walletAddress = data.ott.account
            this.fiatType = data.ott.currency
            return this.userData
        } catch(e) {
          // alert(e)
            throw 'Error getting Token Data'
        }
    },
    async setUserData (key, payloadJSON) {
        try {
            const res = await fetch(`https://xumm.app/api/v1/xapp-jwt/userdata/${key}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.userData.jwt}`
                },
                body: JSON.stringify(payloadJSON)
            })

            if(res.status < 200 || res.status > 299) {
                throw `Status: ${res.status}`
            }

        } catch(e) {
            console.error('Post userData to Xaman:', e)
            // alert(`Error with setting user data ${e}`)
        }
    },
    async getUserData (key) {
        try {
            const res = await fetch(`https://xumm.app/api/v1/xapp-jwt/userdata/${key}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${this.userData.jwt}`
                }
            })

            if(res.status < 200 || res.status > 299) {
                throw `${res.status}`
            }

            const body = await res.json()
            return body.data
        } catch(e) {
            console.error('Error getting userData from Xaman', e)
            throw e
        }
    },
    checkUserData() {
      const access = this.userData.ott.accountaccess
      const node = this.userData.ott.nodetype
      if(access !== 'FULL' && node !== 'MAINNET') throw 'This xApp cannot be opened with a "Read Only" account and with a NodeType is not set to MAINNET, please change this in the "Advanced" settings menu'
      if(access !== 'FULL') throw 'This xApp cannot be opened with a "Read Only" account, please switch to an account that is able to sign with "Full Access"'
      if(node !== 'MAINNET') throw 'This xApp cannot be opened with this NodeType, please change this in the "Advanced" settings menu to "MAINNET"'
    },
    async onboardingCheck() {
      try {
        const dataresult = await this.getUserData('onboarding')
        console.log(JSON.stringify(dataresult))
        console.log('Init value: ', dataresult?.onboarding?.init)
        if ( (dataresult?.onboarding?.init !== 'false' || dataresult?.onboarding?.init !== false) && dataresult?.onboarding?.init != undefined) {
          this.tcAccept = true
          return
        }
        console.log('init: ', this.tcAccept)
      } catch (e) {
        // alert(`Error getting userData from XUMM ${e}`)
      }
    },
    extractParam(searchParams, param) {
      if(!searchParams.has(param)) throw new Error('Missing parameter: ' + param)
      return searchParams.get(param)
    },
    redirectCheck(origin) {
      // origin = 'https://xumm.app/detect/xapp:guardarian.onofframp/send?fromCurrency=xrp&fromNetwork=xrp&amount=1&address=rpzF4U4ZeJU5LbUwornqTi54ieCxsAzack&memo=620737508'
      try {
        const url = new URL(origin)
        const searchParams = url.searchParams

        // memo == Destination Tag hence it is not a XRP native memo
        const params = ['fromCurrency', 'fromNetwork', 'amount', 'address', 'memo']

        const obj = {}
        for(const param of params) {
          obj[param] = this.extractParam(searchParams, param)
        }
        return obj
      } catch(e) {
        const pre = 'Error parsing URL parameters'
        console.error(pre, e)
        throw 'ignore'
      }
    }
  },
  async mounted() {
    try {
        const urlParams = new URLSearchParams(window.location.search)
        const ott = urlParams.get('xAppToken')
        await this.getTokenData(ott)

        try {
          this.checkUserData()
        } catch(e) {
          this.alert(e)
          return window.xapp.close()
        }

        await this.onboardingCheck()

        // If the user is redirected from the payment screen to the xApp pleasse sign a transaction
        const origin = this.userData?.ott?.origin?.data?.url || this.userData?.ott?.origin?.data?.content
        if(origin) {
          const obj = this.redirectCheck(origin)
          const isSuccess = await xummPayment({
            amount: obj.amount,
            currency: 'XRP',
            destinationTag: obj.memo,
            walletOrigin: this.walletAddress,
            walletDestination: obj.address,
            orderId: null
          }, this.userData.jwt)
          if(isSuccess) this.alert('Payment to Guardarian successful')
        }
    } catch(e) {
      if(e !== 'ignore') {
        console.error(e)
        this.alert(e)
      }
    }
    this.loading = false
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html, body {
  background-color: #1B2125;
  margin: 0;
  height: 100vh;
  overflow-x: hidden;
  overscroll-behavior-y: none;
  position: fixed;
  overflow: hidden;
}
body {
  overflow-y: auto;
  overscroll-behavior-y: auto;
  width: 100%;
}
#app {
  font-family: Roboto,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  padding-bottom: 20px;
  min-height: -webkit-fill-available;
}
.terms-condition-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: white;
}
.tc-info {
    border: 1px solid rgb(0 134 251);
    border-radius: 5px;
    background-color: rgb(0 134 251 / 50%);
    margin: auto;
    padding: 0 15px;
}
.terms-condition-container > button {
  background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    color: #666;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    padding: 0.375rem 0.75rem;
    text-align: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    user-select: none;
    vertical-align: middle;
    white-space: normal;
    cursor: pointer;

    width: auto;

    background-color: rgb(0, 134, 251) !important;
    border-color: rgb(0, 134, 251) !important;
    color: #fff !important;
}
.support-button {
  width: 20px;
  fill: white;
  position: absolute;
  right: 15px;
  top: 18px;
}
</style>
