<template>
    <form @submit.prevent="">
        <div class="order-side-button-row">
            <a @click="switchOrderType('buy')" :class="{ selected: orderType == 'buy' }">Buy</a>
            <a @click="switchOrderType('sell')" :class="{ selected: orderType == 'sell' }">Sell</a>
        </div>

        <div style="display: flex; width: inherit;" :style="{ 'flex-direction': orderType == 'buy' ? 'column':'column-reverse' }">
            <div style="position: relative;">
                <fieldset>
                    <div class="quantity-input-label-top">
                        <label :style="{ color: limitErrorFiat ? 'red' : null }">{{ orderType == 'buy' ? 'You send' : 'You get' }}</label>
                        <InputQuantity placeholder="0"  v-model="fiatAmount" />
                    </div>
                    <div class="currency-label">
                        <div class="icon-bg-circle" style="background-color: transparent;">
                            <!-- insert currency image here with logo_url -->
                        </div>
                        <select v-model="selectedFiat" class="currency-select-style">
                            <option v-for="currency in fiatOptions" :value="currency.ticker" :key="currency.ticker">{{ currency.ticker }}</option>
                        </select>
                        <CaretDown class="select-caret"/>
                    </div>

                </fieldset>
                <div v-if="limitErrorFiat || (orderType === 'sell' && !offrampAvailable)" class="limits-info-text">
                    <span v-if="orderType === 'sell' && !offrampAvailable">{{ `Offramp or selling XRP to ${selectedFiat} is not supported` }}</span>
                    <span v-else>{{ ratesError || limitErrorFiat }}</span>
                </div>
            </div>

            <div class="exchange-info">
                <div class="exchange-info-bar"></div>
                <div class="exchange-info-row">
                    <div class="exchange-info-dot"></div>
                    <div class="exchange-info-text">
                        <label>Fees:</label>
                        <template v-if="!loading.rates && ratesData?.service_fees">
                            <span v-for="fee in ratesData?.service_fees" :key="fee">{{ Big(fee.amount).round(6) }} {{ fee.currency }} @{{ Big(fee.percentage).round(2) }}%</span>
                        </template>
                    </div>
                </div>
                <div class="exchange-info-row" style="top: calc(100% / 3 * 2 - 4px);">
                    <div class="exchange-info-dot"></div>
                    <div class="exchange-info-text" v-if="ratesData?.estimated_exchange_rate || true">
                        <label>Estimated Rate: </label>
                        <LoaderDots v-if="loading.rates"/>
                        <span v-else-if="ratesData?.estimated_exchange_rate">1 {{ ratesData.from_currency }} ≈ {{ Big(ratesData.estimated_exchange_rate).round(6) }} {{ ratesData.to_currency }} </span>
                    </div>
                </div>
            </div>

            <div style="position: relative;">
                <fieldset>
                    <div class="quantity-input-label-top">
                        <label :style="{ color: limitErrorCoin ? 'red' : null }">{{ orderType == 'buy' ? 'You get' : 'You send' }}</label>
                        <InputQuantity placeholder="0" v-model="cryptoAmount" :disabled="error.sell" />
                    </div>
                    <div class="currency-label">
                        <div class="icon-bg-circle">
                            <svg style="width: 0.9rem; margin: auto;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 424"><defs></defs><title>x</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M437,0h74L357,152.48c-55.77,55.19-146.19,55.19-202,0L.94,0H75L192,115.83a91.11,91.11,0,0,0,127.91,0Z"/><path class="cls-1" d="M74.05,424H0L155,270.58c55.77-55.19,146.19-55.19,202,0L512,424H438L320,307.23a91.11,91.11,0,0,0-127.91,0Z"/></g></g></svg>
                        </div>
                        <select class="currency-select-style" :disabled="true" value="XRP" style="opacity: 1;">
                            <option style="color: white !important;" value="XRP">XRP</option>
                        </select>
                        <!-- <span class="currency-select-style">XRP</span> -->
                        <div class="select-caret"> <!-- select caret no option for XRP --> </div>
                    </div>
                </fieldset>
                <div v-if="orderLimitCoin && limitErrorCoin" class="limits-info-text">
                    <span>{{ ratesError || limitErrorCoin }}</span>
                </div>
            </div>
        </div>

        <LoaderSpinner v-if="loading.init" style="margin: auto"/>

        <div v-if="!loading.init && (!offrampAvailable && orderType !== 'sell') || error.buy" class="information-box">
            <h4 v-if="!offrampAvailable" class="error-message">{{ `Buying XRP with ${selectedFiat} is available. For selling XRP, there are other currencies available` }}</h4>
            <h4 v-else-if="error.buy" class="error-message">{{ `Onramp or buying XRP in ${selectedFiat} is not supported` }}</h4>
        </div>

        <div style="margin-top: auto; width: 100%;">
            <button type="submit" class="order-btn" @click="submitOrder()" :disabled="limitErrorCoin || limitErrorFiat || !fiatAmount || !cryptoAmount" style="position: relative;">
                {{ orderType }}
                <LoaderSpinner v-show="loading.order" style="width: 20px; height: 20px; position: absolute; left: calc(50% + 60px);"/>
            </button>
        </div>
    </form>
</template>

<script>
import InputQuantity from './InputQuantity.vue'
import CaretDown from '../assets/caret-down.vue'

import Big from 'big.js'
import LoaderSpinner from './LoaderSpinner.vue'
import LoaderDots from './LoaderDots.vue'

export default {
  name: 'OrderForm',
  components: { InputQuantity, CaretDown, LoaderSpinner, LoaderDots },
  props: ['walletAddress', 'fiatType'],
  data() {
    return {
      loading: {
        init: false,
        rates: false,
        order: false
      },
      fiatAmountValue: null,
      ratesTimeout: null,
      cryptoAmountValue: null,
      orderType: 'buy', // sell
      fiatValue: null,
      coins: null,
      fiatOptionsValue: null,
      orderLimitFiat: null,
      orderLimitCoin: null,
      error: {
        buy: false,
        sell: false
      },
      ratesError: null,
      ratesData: null,
      ratesType: null,
      fetchIndex: 0
    }
  },
  computed: {
    limitErrorFiat() {
        if(isNaN(this.fiatAmount) || this.fiatAmount <= 0) return false
        if(isNaN(this.orderLimitFiat?.min) || this.orderLimitFiat?.min <= 0) return false
        if(isNaN(this.orderLimitFiat?.max) || this.orderLimitFiat?.max <= 0) return false

        if(this.Big(this.fiatAmount).lt(this.orderLimitFiat?.min)) return `${this.selectedFiat} amount must be higher than ${this.orderLimitFiat?.min}`
        if(this.Big(this.fiatAmount).gt(this.orderLimitFiat?.max)) return `${this.selectedFiat} amount must be lower than ${this.orderLimitFiat?.max}`

        return false
    },
    limitErrorCoin() {
        if(isNaN(this.cryptoAmount) || this.cryptoAmount <= 0) return false
        if(isNaN(this.orderLimitCoin?.min) || this.orderLimitCoin?.min <= 0) return false
        if(isNaN(this.orderLimitCoin?.max) || this.orderLimitCoin?.max <= 0) return false

        if(this.Big(this.cryptoAmount).lt(this.orderLimitCoin?.min)) return `XRP amount must be higher than ${this.orderLimitCoin?.min}`
        if(this.Big(this.cryptoAmount).gt(this.orderLimitCoin?.max)) return `XRP amount must be lower than ${this.orderLimitCoin?.max}`

        return false
    },
    offrampAvailable() {
        return this.getCurrencyListOptions('sell').map(obj =>  obj.ticker).includes(this.selectedFiat)
    },
    selectedFiat: {
        get() {
            return this.fiatValue || this.fiatType
        },
        async set(value) {
            this.fiatValue = value
            this.cryptoAmountValue = null
            this.selectedPaymentMethodId = null;
            [this.orderLimitFiat, this.orderLimitCoin] = await this.getLimits()
            this.setFiatAmount(this.fiatAmount)
        }
    },
    fiatOptions: {
        get() {
            if(this.fiatOptionsValue?.fiat_currencies?.length < 1 || !this.fiatOptionsValue?.fiat_currencies) return null
            const options = this.getCurrencyListOptions(this.orderType)
            return options
        },
        set(value) {
            this.fiatOptionsValue = value
        }
    },
    fiatAmount: {
      get() {
        if(this.ratesType === 'coin') {
            try {
                return this.Big(this.ratesData?.value).round(2).toString()
            } catch(e) {
                return this.fiatAmountValue
            }
        }
        return this.fiatAmountValue
      },
      set(value) {
        this.fiatAmountValue = value
        this.setFiatAmount(value)
      }
    },
    cryptoAmount: {
      get() {
        if(this.ratesType === 'fiat') {
            try {
                return this.Big(this.ratesData?.value).round(6).toString()
            } catch(e) {
                return this.cryptoAmountValue
            }
        }
        return this.cryptoAmountValue
      },
      set(value) {
        this.cryptoAmountValue = value
        this.setCryptoAmount(value)
      }
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    getCurrencyListOptions(orderType) {
        if(!this.fiatOptionsValue || this.fiatOptionsValue?.length < 1) return []

        let options = []
        for(const option of this.fiatOptionsValue.fiat_currencies) {
            if(!option.enabled) continue
            if(option?.payment_methods?.length < 1 || !option?.payment_methods) continue

            for(const method of option?.payment_methods) {
                if(orderType === 'sell' && method.withdrawal_enabled === true) {
                    options.push(option)
                    break
                } else if(orderType === 'buy' && method.deposit_enabled === true) {
                    options.push(option)
                    break
                }
            }
        }
        return options
    },
    async switchOrderType(type) {
        this.orderType = type;

        if(this.cryptoAmountValue && this.cryptoAmountValue > 0 && !isNaN(this.cryptoAmountValue)) this.setCryptoAmount(this.cryptoAmountValue);
        else if(this.fiatAmountValue && this.fiatAmountValue > 0 && !isNaN(this.fiatAmountValue)) this.setFiatAmount(this.fiatAmountValue);
        [this.orderLimitFiat, this.orderLimitCoin] = await this.getLimits()
    },
    Big(value) {
        try {
            return new Big(value)
        } catch(e) {
            console.log(e)
            console.log(value)
        }
    },
    async getCurrencies() {
        const fiats = await fetch(`${process.env.VUE_APP_API_ENDPOINT}/v1/currencies?available=true`)
        if(fiats.status < 200 || fiats.status > 299) throw fiats.status
        else {
            this.fiatOptions = await fiats.json()
            return this.fiatOptions
        }
    },
    async getLimit(pair) {
        return fetch(`${process.env.VUE_APP_API_ENDPOINT}/v1/market-info/min-max-range/${pair}`)
    },
    async getLimits() {
        const [limitFiat, limitCoin] = await Promise.all([this.getLimit(`${this.selectedFiat.toLowerCase()}_xrp-xrp`), this.offrampAvailable ? this.getLimit(`xrp-xrp_${this.selectedFiat.toLowerCase()}`) : null]);
        if(limitFiat.status < 200 || limitFiat.status > 299) {
            throw limitFiat.status;
        } else this.error.buy = false;

        if(!limitCoin) {
            this.error.sell = true;
        } else if(limitCoin.status < 200 || limitCoin.status > 299) {
            throw limitCoin.status;
        } else this.error.sell = false;

        [this.orderLimitFiat, this.orderLimitCoin] = await Promise.all([limitFiat?.json(), limitCoin?.json()]);
        return [this.orderLimitFiat, this.orderLimitCoin];
        
    },
    async init() {
      this.loading.init = true
      try {
        await this.getCurrencies()
        await this.getLimits()
        this.loading.init = false
        return
      } catch(e) {
        console.log(e)
        alert('Error connecting to Guardarian')
      }
      this.loading.init = false
    },
    async fetchRates(url, fiat) {
        this.loading.rates = true
        this.fetchIndex++
        let fetchI = this.fetchIndex
        try {
            const res = await fetch(url, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'x-api-key': process.env.VUE_APP_API_KEY
                }
            })
            const data = await res.json()
        
            if(fetchI < this.fetchIndex) throw 'Index error'
            if(res.status < 200 || res.status > 299) throw { status: res.status, message: data.message, code: data.code }
            else this.ratesError = null

            if(data.service_fees) {
                data.service_fees.forEach((fee, index) => {
                    data.service_fees[index].percentage = fee.percentage ? fee.percentage.replace('%', '') : fee.percentage
                })
            }
            this.ratesData = data
        
            console.log(data)
            if(fiat) this.ratesType = 'fiat'
            else this.ratesType = 'coin'
        } catch(e) {
            // eslint-disable-next-line
            if(e === 'Index error') {}
            else {
                this.ratesData = null
                this.ratesError = e.message
                console.error('Error fetching rates:', e)        
                // alert('Error fetching rates')
            }
        }
        this.loading.rates = false
    },
    setFiatAmount(value) {
        if(this.ratesTimeout !== null) {
            clearTimeout(this.ratesTimeout)
            this.ratesTimeout = null
        }

        if(isNaN(value) || value <= 0) {
            this.cryptoAmountValue = null
            this.ratesData = null
            return
        }

        if(this.limitErrorFiat) return

        const url = new URL(process.env.VUE_APP_API_ENDPOINT + '/v1/estimate')
        const params = {
            from_currency: this.selectedFiat,
            from_amount: value,
            to_currency: 'XRP',
            to_network: 'XRP'
        }

        url.search = new URLSearchParams(params).toString()
        this.ratesTimeout = setTimeout(this.fetchRates.bind(null, url, 'fiat'), 500)
    },
    setCryptoAmount(value) {
        if(this.ratesTimeout !== null) {
            clearTimeout(this.ratesTimeout)
            this.ratesTimeout = null
        }
      
        if(isNaN(value) || value <= 0) {
            this.fiatAmountValue = null
            this.ratesData = null
            return
        }

        if(this.limitErrorCoin) return

        const url = new URL(process.env.VUE_APP_API_ENDPOINT + '/v1/estimate')
        const params = {
            to_currency: this.selectedFiat,
            from_amount: value,
            from_currency: 'XRP',
            from_network: 'XRP'
        }

        url.search = new URLSearchParams(params).toString()
        this.ratesTimeout = setTimeout(this.fetchRates.bind(null, url), 500)
    },
    methodAvailable(method) {
      return method?.supported_fiat?.includes(this.selectedFiat) && method?.status === 'ACTIVE'
    },
    formatPercentage(fee) {
      return this.Big(fee).times(100).toString() + '%'
    },
    async submitOrder() {
      if(this.loading.order) return

      this.loading.order = true
    //   const returnUrl = process.env.VUE_APP_XAPP_DEEPLINK
      const account = this.walletAddress

      const isBuying = () => {
        return this.orderType === 'buy'
      }

      try {
        const params = {
            from_amount: isBuying() ? this.fiatAmount : this.cryptoAmount,
            from_currency: isBuying() ? this.selectedFiat : 'XRP',
            to_currency: isBuying() ? 'XRP' : this.selectedFiat,
            from_network: isBuying() ? undefined : 'XRP',
            to_network: isBuying() ? 'XRP' : undefined,
            payout_address: isBuying() ? account : undefined
        }

        console.log(params)

        const res = await fetch(process.env.VUE_APP_API_ENDPOINT + '/v1/transaction', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.VUE_APP_API_KEY
          },
          body: JSON.stringify(params)
        })
        const data = await res.json()

        if(res.status < 200 || res.status > 299) {
            throw `${data.code} ${res.status} - ${data.message}`
        }

        console.log(data)
        console.log(data.redirect_url)
        await window.xapp.openBrowser({ url: data.redirect_url })
      } catch(e) {
        console.log('Error submitting order', e)
        alert('Error submitting order: ' + e)
      }
      this.loading.order = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
form {
  background-color: transparent;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  flex: 1 1 0;
  margin: 0 auto;
}
fieldset {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 10px;
  background-color: white;
  width: -webkit-fill-available;
  /* padding: 10px 10px; */
  display: flex;
  flex-direction: row;
  align-items: center;

  z-index: 2;
  position: inherit;
}
fieldset > * {
    height: 40px;
    padding: 10px 0 10px 15px;
}
.payment-option-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}
.payment-option-container button {
  width: 150px;
  max-width: 170px;
  margin: 5px auto;
  opacity: 0.6;
}
.payment-option-container > button > img {
  background-color: white;
  padding: 5px;
  border: 1px solid grey;
  border-radius: 5px;
}
.payment-option-container > button > span:not(:first-child) {
  font-size: 0.9rem;
}
h2 {
  color: #0073d1;
}
h3 {
  margin: 40px 0 0;
}
h4 {
  color: #0073d1;
  margin: 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.order-side-button-row {
    display: flex;
    flex-direction: row;
    width: calc(100% - 8px);
    margin-bottom: 16px;
    padding: 4px;
    background-color: rgba(255, 255, 255, 0.17);
    border-radius: 8px;
}
.order-side-button-row a {
    color: grey;
    font-weight: bold;
    font-size: 1rem;
    width: inherit;
    padding: 8px 0;
    cursor: pointer;
}
.order-side-button-row a.selected {
    background-color: white;
    color: black;
    border-radius: 12px;
}
.quantity-input-label-top {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.quantity-input-label-top label {
    font-size: 0.9rem;
    font-weight: 500;
    margin-right: auto;
    color: #4c9de8;
}
.quantity-input-label-top input {
    border: none;
    background-color: none;
    color: black !important;
}
select {
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-family: inherit;
    appearance: none;
    cursor: pointer;
    min-width: 68px;
}
.currency-label {
    font-size: 20px;
    font-weight: 500;
    color: white;
    background-color: rgb(85, 131, 255);
    min-width: 110px;
    width: 110px;
    border-radius: inherit;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
}
button {
  border: 1px solid transparent;
  color: white;
  outline: none;
  background-color: transparent;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
button:hover {
  cursor: pointer;
  /* border: 1px solid #0073d1; */
  border: 1px solid transparent;
}
button:disabled,
button[disabled] {
  cursor: not-allowed;
  background-color: #6a6a6a52;
  color: grey;
}
button img {
  object-fit: contain;
  max-height: 30px;
  height: 30px;
  max-width: 100px;
  width: 100px;
  border: 1px solid grey;
  margin-bottom: 2px;
}
button span {
  font-size: 1.05rem;
  font-weight: 500;
}
button.selected {
  opacity: 1 !important;
}
button.selected > img {
  border: 3px solid #0073d1;
  margin-top: -2px;
  margin-bottom: 0;
}
.order-btn {
    background-color: #00EBB2;
    color: black;
    padding: 15px 10px;
    border-radius: 10px;
    width: 100%;
    margin-top: 20px;
    font-weight: bold;
    font-size: 1.1rem;
    text-transform: uppercase;
}
.currency-select-style {
    padding-right: 20px;
    z-index: 10;
    font-size: inherit;
    font-weight: inherit;
}
.icon-bg-circle {
    border-radius: 50%;
    background-color: white;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.select-caret {
    width: 20px;
    height: 20px;
    fill: white;
    /* margin-left: auto; */
    position: relative;
    left: -20px;
    margin-right: -20px;
}
.limits-info-text {
    font-size: 0.9rem;
    line-height: 0.9rem;
    margin-top: 2px;
    margin-bottom: calc(-0.9rem - 2px);
    margin-right: auto;
    margin-left: 20px;

    position: absolute;
    background-color: #d4322b;
    height: 90px;
    z-index: 1;
    top: -6px;
    left: calc(-20px - 4px);
    right: -4px;
    border-radius: 8px;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 4px;
}
.exchange-info {
    height: 72px;
    position: relative;
}
.exchange-info-bar {
    width: 4px;
    height: inherit;
    background-color: rgb(85, 131, 255);
    position: absolute;
    left: 10px;
}
.exchange-info-row {
    position: absolute;
    left: 8px;
    top: calc(100% / 3 - 4px);
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
}
.exchange-info-text {
    margin-left: 15px;
    position: absolute;
    top: -0.3rem;
    font-size: 0.9rem;
    display: flex;
    flex-direction: row;
}
.exchange-info-row label {
    text-decoration: underline;
    margin-right: 5px;
}
.exchange-info-row span {
    text-decoration: none;
    font-weight: 600;
}
.exchange-info-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #5583FF;
    position: fixed;
}
.information-box {
    padding: 20px 0;
}
.information-box .error-message {
    /* color: #d4322b; */
}
</style>
